const BROWSE_DATA = [
  {
    name: 'Sneakers',
    initialShowLimit: 7,
    children: [
      {
        name: 'adidas',
        initialShowLimit: 6,
        children: [
          { name: 'Adilette' },
          {
            name: 'NMD',
            children: [
              { name: 'NMD_C1' },
              { name: 'NMD_CS1' },
              { name: 'NMD_CS2' },
              { name: 'NMD_R1' },
              { name: 'NMD_R2' },
              { name: 'NMD_Racer' },
              { name: 'NMD_TS1' },
              { name: 'NMD_XR1' },
              { name: 'Pharrell Williams' },
              { name: 'Others' },
            ],
          },
          { name: 'Superstar' },
          { name: 'Stan Smith' },
          {
            name: 'Spezial',
            children: [
              { name: 'Running' },
              { name: 'Skateboarding' },
              { name: 'Soccer' },
              { name: 'Training' },
              { name: 'Volleyball' },
            ],
          },
          {
            name: 'UltraBoost',
            children: [
              { name: 'UltraBoost 1.0' },
              { name: 'UltraBoost 2.0' },
              { name: 'UltraBoost 3.0' },
              { name: 'UltraBoost 4.0' },
              { name: 'UltraBoost 5.0' },
              { name: 'UltraBoost 19' },
              { name: 'UltraBoost 20' },
              { name: 'UltraBoost 21' },
              { name: 'UltraBoost 22' },
              { name: 'UltraBoost DNA' },
              { name: 'UltraBoost S&L' },
              { name: 'UltraBoost X' },
              { name: 'Others' },
            ],
          },
          {
            name: 'Yeezy',
            children: [
              { name: 'Foam Runner' },
              { name: 'Yeezy 350' },
              { name: 'Yeezy 380' },
              { name: 'Yeezy 500' },
              { name: 'Yeezy 700' },
              { name: 'Yeezy Slide' },
              { name: 'Others' },
            ],
          },
          { name: 'ZX' },
          { name: 'Adizero', children: [{ name: 'Basketball' }, { name: 'Running' }] },
          {
            name: 'Basketball',
            children: [
              { name: 'D.O.N. Issue' },
              { name: 'Dame' },
              { name: 'Harden' },
              { name: 'T-Mac' },
              { name: 'Others' },
            ],
          },
          { name: 'Campus', children: [{ name: 'Campus 00s' }] },
          { name: 'Continental' },
          { name: 'EQT' },
          { name: 'Forum', children: [{ name: 'High' }, { name: 'Low' }, { name: 'Mid' }] },
          { name: 'Gazelle' },
          { name: 'Iniki' },
          { name: 'Nite Jogger' },
          { name: 'Nizza' },
          { name: 'Ozweego' },
          { name: 'Samba' },
          { name: 'Slide' },
          { name: 'Trae Young' },
          { name: 'Triple S' },
          {
            name: 'Others',
            children: [
              { name: 'Baseball' },
              { name: 'Basketball' },
              { name: 'Golf' },
              { name: 'Hiking' },
              { name: 'Hiking/Outdoor' },
              { name: 'Running' },
              { name: 'Skateboarding' },
              { name: 'Soccer' },
              { name: 'Tennis' },
              { name: 'Training' },
              { name: 'Volleyball' },
              { name: 'Others' },
            ],
          },
        ],
      },
      {
        name: 'Nike',
        initialShowLimit: 8,
        children: [
          { name: 'Air Force', children: [{ name: 'Air Force 1' }, { name: 'Others' }] },
          { name: 'Fear of God', children: [{ name: 'Air Fear of God 1' }, { name: 'Others' }] },
          {
            name: 'Air Max',
            children: [
              { name: 'Air Max 1' },
              { name: 'Air Max 90' },
              { name: 'Air Max 95' },
              { name: 'Air Max 96' },
              { name: 'Air Max 97' },
              { name: 'Air Max 98' },
              { name: 'Air Max 200' },
              { name: 'Air Max 270' },
              { name: 'Air Max 720' },
              { name: 'Air Max Plus' },
              { name: 'VaporMax' },
              { name: 'Others' },
            ],
          },
          { name: 'Dunk', children: [{ name: 'High' }, { name: 'Low' }, { name: 'Mid' }] },
          {
            name: 'Kobe',
            children: [
              { name: 'A.D.' },
              { name: 'Kobe 1' },
              { name: 'Kobe 2' },
              { name: 'Kobe 3' },
              { name: 'Kobe 4' },
              { name: 'Kobe 5' },
              { name: 'Kobe 6' },
              { name: 'Kobe 7' },
              { name: 'Kobe 8' },
              { name: 'Kobe 9' },
              { name: 'Kobe 10' },
              { name: 'Kobe 11' },
              { name: 'Others' },
            ],
          },
          {
            name: 'LeBron',
            children: [
              { name: 'LeBron 1' },
              { name: 'Lebron 2' },
              { name: 'LeBron 3' },
              { name: 'Lebron 4' },
              { name: 'Lebron 5' },
              { name: 'LeBron 6' },
              { name: 'LeBron 7' },
              { name: 'LeBron 8' },
              { name: 'LeBron 9' },
              { name: 'LeBron 10' },
              { name: 'LeBron 11' },
              { name: 'LeBron 12' },
              { name: 'LeBron 13' },
              { name: 'LeBron 14' },
              { name: 'LeBron 15' },
              { name: 'LeBron 16' },
              { name: 'LeBron 17' },
              { name: 'LeBron 18' },
              { name: 'LeBron 19' },
              { name: 'Lebron 20' },
              { name: 'Soldier' },
              { name: 'Others' },
            ],
          },
          {
            name: 'SB',
            children: [
              { name: 'High' },
              { name: 'Low' },
              { name: 'SB Blazer' },
              { name: 'SB Dunk' },
              { name: 'SB Janoski' },
              { name: 'Others' },
            ],
          },
          {
            name: 'Waffle',
            children: [
              { name: 'LDWaffle' },
              { name: 'VaporWaffle' },
              { name: 'Waffle Racer' },
              { name: 'Waffle Trainer' },
              { name: 'Others' },
            ],
          },
          { name: 'Adapt' },
          { name: 'Air Flight Lite', children: [{ name: 'Mid' }] },
          { name: 'Air Trainer' },
          {
            name: 'Basketball',
            children: [
              { name: 'Air Uptempo' },
              { name: 'Barkley' },
              { name: 'Hyperdunk' },
              { name: 'Penny' },
              { name: 'Others' },
            ],
          },
          { name: 'Blazer', children: [{ name: 'High' }, { name: 'Low' }, { name: 'Mid' }] },
          { name: 'Cortez' },
          { name: 'Cosmic Unity' },
          { name: 'Foamposite' },
          { name: 'Freak' },
          { name: 'Free' },
          {
            name: 'Giannis',
            children: [
              { name: 'Freak 1' },
              { name: 'Freak 2' },
              { name: 'Freak 3' },
              { name: 'Freak 4' },
              { name: 'Others' },
            ],
          },
          { name: 'Huarache' },
          {
            name: 'KD',
            children: [
              { name: 'KD 1' },
              { name: 'KD 2' },
              { name: 'KD 3' },
              { name: 'KD 4' },
              { name: 'KD 5' },
              { name: 'KD 6' },
              { name: 'KD 7' },
              { name: 'KD 8' },
              { name: 'KD 9' },
              { name: 'KD 10' },
              { name: 'KD 11' },
              { name: 'KD 12' },
              { name: 'KD 13' },
              { name: 'KD 14' },
              { name: 'KD 15' },
              { name: 'Others' },
            ],
          },
          {
            name: 'Kyrie',
            children: [
              { name: 'Kyrie 1' },
              { name: 'Kyrie 2' },
              { name: 'Kyrie 3' },
              { name: 'Kyrie 4' },
              { name: 'Kyrie 5' },
              { name: 'Kyrie 6' },
              { name: 'Kyrie 7' },
              { name: 'Kyrie 8' },
              { name: 'Others' },
            ],
          },
          { name: 'Legacy 312' },
          { name: 'Mercurial' },
          { name: 'Metcon' },
          { name: 'Pegasus' },
          {
            name: 'PG',
            children: [
              { name: 'PG 1' },
              { name: 'PG 2' },
              { name: 'PG 3' },
              { name: 'PG 4' },
              { name: 'PG 5' },
              { name: 'PG 6' },
            ],
          },
          { name: 'Presto' },
          { name: 'Quest' },
          {
            name: 'React',
            children: [
              { name: 'React Element 55' },
              { name: 'React Element 87' },
              { name: 'React Infinity' },
              { name: 'Others' },
            ],
          },
          {
            name: 'Renew',
            children: [{ name: 'Renew Elevate' }, { name: 'Renew Run' }, { name: 'Others' }],
          },
          { name: 'Revolution', children: [{ name: 'Revolution 6' }] },
          { name: 'Shox' },
          { name: 'Slide' },
          {
            name: 'Space Hippie',
            children: [
              { name: 'Space Hippie 01' },
              { name: 'Space Hippie 02' },
              { name: 'Space Hippie 03' },
              { name: 'Space Hippie 04' },
            ],
          },
          { name: 'Spiridon', children: [{ name: 'Spiridon Cage 2' }, { name: 'Others' }] },
          { name: 'SuperRep' },
          { name: 'VaporFly' },
          { name: 'Vomero' },
          { name: 'Zoom' },
          {
            name: 'Others',
            children: [
              { name: 'Air Force 1' },
              { name: 'Air Uptempo' },
              { name: 'Baseball' },
              { name: 'Basketball' },
              { name: 'Football' },
              { name: 'Golf' },
              { name: 'Hiking' },
              { name: 'Hiking/Outdoor' },
              { name: 'Low' },
              { name: 'Premium' },
              { name: 'Running' },
              { name: 'Skateboarding' },
              { name: 'Soccer' },
              { name: 'Tennis' },
              { name: 'Training' },
              { name: 'Volleyball' },
              { name: 'Vomero' },
              { name: 'Others' },
            ],
          },
        ],
      },
      {
        name: 'Jordan',
        initialShowLimit: 10,
        children: [
          { name: 'Jordan 1', children: [{ name: 'High' }, { name: 'Low' }, { name: 'Mid' }] },
          { name: 'Jordan 2' },
          { name: 'Jordan 3' },
          { name: 'Jordan 4' },
          { name: 'Jordan 5' },
          { name: 'Jordan 6', children: [{ name: 'Rings' }] },
          { name: 'Jordan 7' },
          { name: 'Jordan 8' },
          { name: 'Jordan 9' },
          { name: 'Jordan 10' },
          { name: 'Jordan 11', children: [{ name: 'High' }, { name: 'Low' }] },
          { name: 'Jordan 12', children: [{ name: 'Low' }] },
          { name: 'Jordan 13', children: [{ name: 'Low' }] },
          { name: 'Jordan 14' },
          { name: 'Jordan 15' },
          { name: 'Jordan 16' },
          { name: 'Jordan 17' },
          { name: 'Jordan 18' },
          { name: 'Jordan 19' },
          { name: 'Jordan 20' },
          { name: 'Jordan 21' },
          { name: 'Jordan 22' },
          { name: 'Jordan 23' },
          { name: 'Jordan 24' },
          { name: 'Jordan 25' },
          { name: 'Jordan 26' },
          { name: 'Jordan 27' },
          { name: 'Jordan 28' },
          { name: 'Jordan 29' },
          { name: 'Jordan 30' },
          { name: 'Jordan 31' },
          { name: 'Jordan 32' },
          { name: 'Jordan 33' },
          { name: 'Jordan 34' },
          { name: 'Jordan 35' },
          { name: 'Jordan 36' },
          { name: 'Jordan 37' },
          { name: 'Legacy 312' },
          { name: 'Luka' },
          { name: 'Slide' },
          { name: 'Spizike' },
          { name: 'Zion' },
          {
            name: 'Others',
            children: [
              { name: 'Baseball' },
              { name: 'Basketball' },
              { name: 'Golf' },
              { name: 'High' },
              { name: 'Hiking' },
              { name: 'Low' },
              { name: 'Running' },
              { name: 'Tennis' },
              { name: 'Training' },
            ],
          },
        ],
      },
      {
        name: 'New Balance',
        children: [
          { name: '327' },
          { name: '550' },
          { name: '992' },
          { name: '990' },
          { name: '2002R' },
          { name: '57/40' },
          { name: '530' },
          { name: '574' },
          {
            name: '9060',
            children: [{ name: 'Hiking' }, { name: 'Running' }, { name: 'Tennis' }],
          },
          { name: '990v1' },
          { name: '990v2' },
          { name: '990v3' },
          { name: '990v4' },
          { name: '990v5' },
          { name: '991' },
          { name: '993' },
          { name: '996' },
          { name: '997' },
          { name: '998' },
          {
            name: '1906',
            children: [
              { name: '1906' },
              { name: '1906A' },
              { name: '1906D' },
              { name: '1906F' },
              { name: '1906L' },
              { name: '1906R' },
              { name: '1906U' },
            ],
          },
          { name: 'FuelCell' },
          { name: 'OMN1S' },
          {
            name: 'Others',
            children: [
              { name: 'Baseball' },
              { name: 'Basketball' },
              { name: 'Golf' },
              { name: 'Hiking' },
              { name: 'Hiking/Outdoor' },
              { name: 'Running' },
              { name: 'Skateboarding' },
              { name: 'Soccer' },
              { name: 'Tennis' },
              { name: 'Training' },
              { name: 'Others' },
            ],
          },
        ],
      },
      {
        name: 'ASICS',
        children: [
          {
            name: 'Gel 1090',
            children: [{ name: 'Basketball' }, { name: 'Running' }],
          },
          {
            name: 'Gel 1130',
            children: [{ name: 'Hiking' }, { name: 'Running' }],
          },
          { name: 'Gel Contend' },
          {
            name: 'Gel Cumulus',
            children: [{ name: 'Baseball' }, { name: 'Basketball' }],
          },
          {
            name: 'Gel Excite',
            children: [{ name: 'Basketball' }, { name: 'Running' }],
          },
          { name: 'Gel Kayano' },
          { name: 'Gel Lyte' },
          { name: 'Gel Nimbus' },
          { name: 'Gel NYC', children: [{ name: 'Running' }] },
          { name: 'Gel Quantum' },
          {
            name: 'Gel Resolution',
            children: [{ name: 'Basketball' }, { name: 'Running' }, { name: 'Tennis' }],
          },
          {
            name: 'Gel Venture',
            children: [{ name: 'Basketball' }, { name: 'Running' }],
          },
          {
            name: 'GT 1000',
            children: [
              { name: 'Basketball' },
              { name: 'Running' },
              { name: 'Skateboarding' },
              { name: 'Training' },
            ],
          },
          {
            name: 'GT 2000',
            children: [{ name: 'Basketball' }, { name: 'Running' }, { name: 'Training' }],
          },
          { name: 'GT 2160' },
          { name: 'Metaspeed', children: [{ name: 'Running' }] },
          { name: 'Mexico 66' },
          {
            name: 'Novablast',
            children: [{ name: 'Basketball' }, { name: 'Running' }],
          },
          { name: 'Superblast' },
          {
            name: 'Others',
            children: [
              { name: 'Basketball' },
              { name: 'Golf' },
              { name: 'Running' },
              { name: 'Soccer' },
              { name: 'Tennis' },
              { name: 'Training' },
              { name: 'Volleyball' },
              { name: 'Others' },
            ],
          },
        ],
      },
      {
        name: 'Converse',
        children: [
          { name: 'Chuck Taylor' },
          { name: 'One Star' },
          { name: 'Run Star' },
          {
            name: 'Others',
            children: [
              { name: 'Basketball' },
              { name: 'Golf' },
              { name: 'Hiking' },
              { name: 'Running' },
              { name: 'Skateboarding' },
            ],
          },
        ],
      },
      {
        name: 'Vans',
        children: [
          { name: 'Authentic' },
          { name: 'Era' },
          { name: 'Half Cab' },
          { name: 'Old Skool' },
          { name: 'Sk8' },
          { name: 'Slip-On' },
          {
            name: 'Others',
            children: [
              { name: 'Basketball' },
              { name: 'Hiking' },
              { name: 'Running' },
              { name: 'Skateboarding' },
            ],
          },
        ],
      },
      { name: '361 Degrees' },
      { name: 'A-Cold-Wall' },
      { name: 'Acne Studios' },
      { name: 'Acupuncture' },
      { name: 'Alexander McQueen' },
      { name: 'Alexander Wang' },
      { name: 'Anta', children: [{ name: 'Kyrie' }, { name: 'Others' }] },
      { name: 'Arcteryx' },
      { name: 'Armani Exchange' },
      { name: 'AMBUSH' },
      { name: 'ASSC' },
      { name: 'AUTRY' },
      {
        name: 'Balenciaga',
        children: [
          { name: 'Slide' },
          { name: 'Speed Trainer' },
          { name: 'Stan Smith' },
          { name: 'Track Trainer' },
          { name: 'Triple S' },
          {
            name: 'Others',
            children: [{ name: 'Basketball' }, { name: 'Running' }],
          },
        ],
      },
      { name: 'BALLY' },
      { name: 'Balmain' },
      {
        name: 'BAPE',
        children: [
          { name: '1461' },
          { name: 'Bape SK8 Sta' },
          { name: 'Bapesta' },
          { name: 'Others', children: [{ name: 'Basketball' }, { name: 'Golf' }] },
        ],
      },
      { name: 'Billionaire Boys Club' },
      { name: 'Birkenstock' },
      { name: 'Bottega Veneta' },
      {
        name: 'Brooks',
        children: [
          {
            name: 'Adrenaline GTS',
            children: [
              { name: 'Adrenaline GTS 14' },
              { name: 'Adrenaline GTS 18' },
              { name: 'Adrenaline GTS 20' },
              { name: 'Adrenaline GTS 21' },
              { name: 'Adrenaline GTS 22' },
              { name: 'Adrenaline GTS 23' },
            ],
          },
          {
            name: 'Ariel',
            children: [{ name: 'Ariel 20' }, { name: 'Ariel 23' }],
          },
          {
            name: 'Beast',
            children: [{ name: 'Beast 20' }, { name: 'Beast 23' }],
          },
          {
            name: 'Ghost',
            children: [
              { name: 'Ghost 12' },
              { name: 'Ghost 13' },
              { name: 'Ghost 14' },
              { name: 'Ghost 15' },
              { name: 'Ghost Max' },
            ],
          },
          {
            name: 'Glycerin',
            children: [
              { name: 'Glycerin 16' },
              { name: 'Glycerin 18' },
              { name: 'Glycerin 19' },
              { name: 'Glycerin 20' },
              { name: 'Glycerin 21' },
            ],
          },
          {
            name: 'Launch',
            children: [{ name: 'Launch 8' }, { name: 'Launch 9' }, { name: 'Launch 10' }],
          },
          { name: 'Others', children: [{ name: 'Running' }] },
        ],
      },
      { name: 'Burberry' },
      { name: 'By Far' },
      { name: 'CAT' },
      { name: 'CELINE' },
      { name: 'Champion' },
      { name: 'Chanel' },
      { name: 'CHARLES&KEITH' },
      { name: 'Chloe' },
      { name: 'Christian Louboutin' },
      { name: 'Clarks' },
      { name: 'Coach' },
      { name: 'Cole Haan' },
      { name: 'Columbia' },
      { name: 'Comme des Garçons' },
      { name: 'Common Projects' },
      { name: 'Crocs', children: [{ name: 'Clog' }, { name: 'Slide' }, { name: 'Others' }] },
      { name: 'DC Shoes' },
      { name: 'DESCENTE' },
      {
        name: 'Diadora',
        children: [
          { name: 'Eclipse' },
          { name: 'Intrepid' },
          { name: 'N9000' },
          { name: 'V7000' },
          { name: 'Others' },
        ],
      },
      { name: 'Diamond Supply Co.' },
      { name: 'Dickies' },
      { name: 'Dior' },
      {
        name: 'Dr. Martens',
        children: [
          { name: '3-Eye' },
          { name: '5-Eye' },
          { name: '6-Eye' },
          { name: '8-Eye' },
          { name: '939 Boot' },
          { name: '1460' },
          { name: '1461' },
          { name: '1490' },
          { name: '8053' },
          { name: 'Chelsea' },
          { name: 'Hollingborn' },
          { name: 'Penton Loafer' },
          { name: 'Platform' },
          { name: 'Tarik Zip' },
          { name: 'Others', children: [{ name: 'Hiking' }] },
        ],
      },
      { name: 'ECCO' },
      { name: 'Emporio Armani' },
      { name: 'ERKE' },
      { name: 'Ewing Athletics' },
      {
        name: 'Fear of God',
        children: [{ name: 'Slide' }, { name: 'The California' }, { name: 'Others' }],
      },
      { name: 'Fendi' },
      { name: 'FERRAGAMO' },
      {
        name: 'FILA',
        children: [
          { name: 'Court Deluxe' },
          { name: 'Disruptor' },
          { name: 'Grant Hill 1' },
          { name: 'Grant Hill 2' },
          { name: 'Ravagement' },
          { name: 'Ray' },
          { name: 'Others' },
        ],
      },
      { name: 'Gant' },
      { name: 'Giorgio Armani' },
      { name: 'Giuseppe Zanotti' },
      { name: 'Givenchy' },
      { name: 'Golden Goose' },
      {
        name: 'Gucci',
        children: [
          { name: 'Gazelle' },
          {
            name: 'Others',
            children: [{ name: 'Basketball' }, { name: 'Running' }, { name: 'Skateboarding' }],
          },
        ],
      },
      { name: 'Hender Scheme' },
      { name: 'HERMES' },
      { name: 'Heron Preston' },
      {
        name: 'HOKA',
        children: [
          { name: 'Arahi', children: [{ name: 'Running' }] },
          { name: 'Arahi 6', children: [{ name: 'Running' }] },
          { name: 'Bondi', children: [{ name: 'Running' }] },
          { name: 'Bondi 8', children: [{ name: 'Running' }] },
          { name: 'Clifton' },
          { name: 'Clifton 8', children: [{ name: 'Running' }] },
          { name: 'Clifton 9', children: [{ name: 'Running' }] },
          { name: 'Mach', children: [{ name: 'Running' }] },
          { name: 'Speedgoat', children: [{ name: 'Running' }] },
          { name: 'Others', children: [{ name: 'Running' }] },
        ],
      },
      { name: 'Jeep' },
      { name: 'Jimmy Choo' },
      { name: 'Just Don' },
      { name: 'KangaROOS' },
      { name: 'Kappa' },
      { name: 'Karhu' },
      { name: 'Keds' },
      { name: 'KEEN' },
      { name: 'Kenzo' },
      { name: 'KITH' },
      { name: 'Lanvin' },
      { name: 'Le Coq Sportif' },
      { name: "Levi's" },
      {
        name: 'Li-Ning',
        children: [{ name: 'Dwyane Wade' }, { name: 'Others', children: [{ name: 'Running' }] }],
      },
      { name: 'LOEWE' },
      {
        name: 'Louis Vuitton',
        children: [
          {
            name: 'Others',
            children: [
              { name: 'Basketball' },
              { name: 'Golf' },
              { name: 'Hiking' },
              { name: 'Others' },
            ],
          },
        ],
      },
      { name: 'Maison Margiela' },
      { name: 'Manolo Blahnik' },
      { name: 'Marni' },
      { name: 'MCM' },
      { name: 'Merrell' },
      { name: 'MICHAEL KORS' },
      { name: 'MIHARA YASUHIRO' },
      { name: 'MIU MIU' },
      {
        name: 'Mizuno',
        children: [
          { name: 'Wave Horizon' },
          { name: 'Wave Inspire' },
          { name: 'Wave Rebellion Pro' },
          {
            name: 'Wave Rider',
            children: [{ name: 'Basketball' }, { name: 'Running' }],
          },
          { name: 'Wave Sky' },
          {
            name: 'Others',
            children: [{ name: 'Basketball' }, { name: 'Running' }],
          },
        ],
      },
      { name: 'MLB' },
      { name: 'Moncler' },
      { name: 'MSCHF' },
      { name: 'NBA' },
      { name: 'NoTwoWays' },
      { name: 'Novelship' },
      { name: 'OAMC' },
      { name: 'Off-White' },
      {
        name: 'On',
        children: [
          { name: 'Cloud' },
          { name: 'Cloud X' },
          { name: 'Cloudflyer' },
          { name: 'Cloudmonster' },
          { name: 'Cloudnova', children: [{ name: 'Running' }] },
          { name: 'Cloudswift' },
          { name: 'Others' },
        ],
      },
      {
        name: 'Onitsuka Tiger',
        children: [
          { name: 'Advanti' },
          { name: 'California 78' },
          { name: 'Delegation' },
          { name: 'GSM' },
          {
            name: 'Mexico 66',
            children: [
              { name: 'Mexico 66 Sabot' },
              { name: 'Mexico 66 SD' },
              { name: 'Mexico 66 Slip On' },
            ],
          },
          { name: 'Sclaw' },
          { name: 'Serrano' },
          { name: 'Tokuten' },
          { name: 'Ultimate 81' },
          { name: 'Others' },
        ],
      },
      { name: 'Palace' },
      { name: 'Palladium' },
      { name: 'Palm Angels' },
      { name: "Pantofalo d'Oro" },
      { name: 'Peak' },
      { name: 'PONY' },
      { name: 'Prada' },
      {
        name: 'Puma',
        children: [
          {
            name: 'LaMelo Ball',
            children: [{ name: 'MB.01' }, { name: 'MB.02' }, { name: 'MB.03' }],
          },
          { name: 'Mayze Flutur' },
          { name: 'Suede' },
          {
            name: 'Others',
            children: [
              { name: 'Basketball' },
              { name: 'Golf' },
              { name: 'Hiking' },
              { name: 'Running' },
              { name: 'Skateboarding' },
              { name: 'Soccer' },
              { name: 'Tennis' },
              { name: 'Training' },
            ],
          },
        ],
      },
      {
        name: 'Reebok',
        children: [
          { name: 'Answer' },
          { name: 'Bapesta' },
          { name: 'Classic Leather' },
          { name: 'Club C' },
          { name: 'InstaPump' },
          { name: 'Kamikaze' },
          { name: 'Pump' },
          { name: 'Question', children: [{ name: 'Low' }, { name: 'Mid' }] },
          { name: 'Shaq' },
          { name: 'Zig Kinetica' },
          { name: 'ZX' },
          {
            name: 'Others',
            children: [
              { name: 'Basketball' },
              { name: 'Golf' },
              { name: 'Hiking' },
              { name: 'Running' },
              { name: 'Tennis' },
              { name: 'Training' },
              { name: 'Others' },
            ],
          },
        ],
      },
      { name: 'Represent' },
      { name: 'Rhude' },
      { name: 'Rick Owens' },
      { name: 'Rick Owens DRKSHDW' },
      { name: 'Rigorer' },
      { name: 'Roger Vivier' },
      { name: 'Saint Laurent' },
      { name: 'sacai' },
      {
        name: 'Salomon',
        children: [
          { name: 'ACS Pro', children: [{ name: 'Running' }] },
          { name: 'Speedcross' },
          { name: 'XT-4' },
          { name: 'XT-6', children: [{ name: 'Running' }] },
          { name: 'Others', children: [{ name: 'Running' }] },
        ],
      },
      {
        name: 'Saucony',
        children: [
          { name: 'Endorphin Pro' },
          { name: 'Endorphin Speed', children: [{ name: 'Running' }] },
          { name: 'Jazz', children: [{ name: 'Basketball' }] },
          {
            name: 'Kinvara',
            children: [{ name: 'Basketball' }, { name: 'Running' }],
          },
          { name: 'Peregrine' },
          { name: 'Ride' },
          { name: 'Shadow 5000' },
          { name: 'Shadow 6000' },
          { name: 'Tempus' },
          { name: 'Triumph', children: [{ name: 'Running' }] },
          {
            name: 'Others',
            children: [{ name: 'Basketball' }, { name: 'Running' }, { name: 'Training' }],
          },
        ],
      },
      { name: 'Skechers' },
      { name: 'STARTER' },
      { name: 'Stuart Weitzman' },
      { name: 'Supreme' },
      { name: 'TEVA' },
      {
        name: 'The North Face',
        children: [
          {
            name: 'Others',
            children: [{ name: 'Basketball' }, { name: 'Running' }],
          },
        ],
      },
      { name: 'Thom Browne' },
      {
        name: 'Timberland',
        children: [
          { name: 'Euro Hiker', children: [{ name: 'Low' }] },
          { name: 'Others', children: [{ name: 'Basketball' }, { name: 'Running' }] },
        ],
      },
      { name: 'TOD‘S' },
      { name: 'TORY BURCH' },
      {
        name: 'UGG',
        children: [
          {
            name: 'Boots',
            children: [
              { name: 'Bailey' },
              { name: 'Classic' },
              { name: 'Classic Dipper' },
              { name: 'Classic Mini' },
              { name: 'Classic Short' },
              { name: 'Classic Tall' },
              { name: 'Classic Ultra Mini' },
              { name: 'Neumel' },
            ],
          },
          {
            name: 'Slippers',
            children: [
              { name: 'Ansley' },
              { name: 'Ascot' },
              { name: 'Classic' },
              { name: 'Clog' },
              { name: 'Coquette' },
              { name: 'Disquette' },
              { name: 'Fluff Yeah' },
              { name: 'Scuffette' },
              { name: 'Tasman' },
              { name: 'Tazz' },
            ],
          },
          { name: 'Slide' },
          { name: 'Others' },
        ],
      },
      {
        name: 'Under Armour',
        children: [
          {
            name: 'Others',
            children: [
              { name: 'Basketball' },
              { name: 'Boxing' },
              { name: 'Cycling' },
              { name: 'Golf' },
              { name: 'Others' },
              { name: 'Running' },
              { name: 'Skateboarding' },
            ],
          },
        ],
      },
      { name: 'Valentino' },
      {
        name: 'VEJA',
        children: [
          { name: 'Campo' },
          { name: 'Esplar' },
          { name: 'Recife' },
          { name: 'Rio Branco' },
          { name: 'V10' },
          { name: 'V12' },
          { name: 'Others' },
        ],
      },
      { name: 'Versace' },
      { name: 'Warrior' },
      { name: 'Xtep' },
      { name: 'xVESSEL' },
      { name: 'Y-3' },
      { name: 'Yonex' },
    ],
  },
  {
    name: 'Apparel',
    initialShowLimit: 10,
    children: [
      {
        name: 'Accessories',
        children: [
          {
            name: 'Belts',
            children: [
              { name: 'ASSC' },
              { name: 'Balenciaga' },
              { name: 'BAPE' },
              { name: 'FTP' },
              { name: 'Off-White' },
              { name: 'Palace' },
              { name: 'Supreme' },
              { name: 'Virgil Abloh' },
            ],
          },
          {
            name: 'Eyewear',
            children: [
              { name: 'Dior' },
              { name: 'Fear of God' },
              { name: 'KAWS' },
              { name: 'Palace' },
              { name: 'Supreme' },
            ],
          },
          {
            name: 'Face Masks',
            children: [
              { name: 'ASSC' },
              { name: 'BAPE' },
              { name: 'Juice Wrld' },
              { name: 'NBA' },
              { name: 'Nike' },
              { name: 'Off-White' },
              { name: 'Palace' },
              { name: 'Takashi Murakami' },
              { name: 'Travis Scott' },
              { name: 'Yeezy' },
            ],
          },
          {
            name: 'Socks',
            children: [
              { name: 'adidas' },
              { name: 'ASSC' },
              { name: 'BAPE' },
              { name: 'Drew House' },
              { name: 'Fear of God' },
              { name: 'FTP' },
              { name: 'KAWS' },
              { name: 'KITH' },
              { name: 'NBA' },
              { name: 'Nike' },
              { name: 'Off-White' },
              { name: 'Palace' },
              { name: 'Supreme' },
              { name: 'Takashi Murakami' },
              { name: 'Travis Scott' },
              { name: 'UNDEFEATED' },
              { name: 'Virgil Abloh' },
            ],
          },
          {
            name: 'Others',
            children: [
              { name: 'BAPE' },
              { name: 'Carhartt WIP' },
              { name: 'Fear of God' },
              { name: 'FTP' },
              { name: 'Givenchy' },
              { name: 'KAWS' },
              { name: 'Nike' },
              { name: 'Off-White' },
              { name: 'Palace' },
              { name: 'Supreme' },
              { name: 'Travis Scott' },
              { name: 'Verdy' },
            ],
          },
        ],
      },
      {
        name: 'Bags',
        children: [
          {
            name: 'Backpacks',
            children: [
              { name: 'BAPE' },
              { name: 'Carhartt WIP' },
              { name: 'Fear of God' },
              { name: 'FTP' },
              { name: 'Nike' },
              { name: 'Off-White' },
              { name: 'Palace' },
              { name: 'Supreme' },
              { name: 'Travis Scott' },
            ],
          },
          {
            name: 'Shoulder Bag',
            children: [
              { name: 'ASSC' },
              { name: 'Balenciaga' },
              { name: 'RIPNDIP' },
              { name: 'Supreme' },
            ],
          },
          {
            name: 'Slings',
            children: [
              { name: 'ASSC' },
              { name: 'Balenciaga' },
              { name: 'BAPE' },
              { name: 'Carhartt WIP' },
              { name: 'Champion' },
              { name: 'Fear of God' },
              { name: 'FTP' },
              { name: 'Givenchy' },
              { name: 'IKEA' },
              { name: 'Juice Wrld' },
              { name: 'KAWS' },
              { name: 'Kenzo' },
              { name: 'NBA' },
              { name: 'Nike' },
              { name: 'Off-White' },
              { name: 'Palace' },
              { name: 'Stüssy' },
              { name: 'Supreme' },
              { name: 'Takashi Murakami' },
              { name: 'Travis Scott' },
              { name: 'Verdy' },
              { name: 'Virgil Abloh' },
            ],
          },
          {
            name: 'Others',
            children: [
              { name: 'ASSC' },
              { name: 'BAPE' },
              { name: 'BTS' },
              { name: 'Carhartt WIP' },
              { name: 'FTP' },
              { name: 'IKEA' },
              { name: 'KAWS' },
              { name: 'Kenzo' },
              { name: 'Louis Vuitton' },
              { name: 'Off-White' },
              { name: 'Palace' },
              { name: 'Supreme' },
              { name: 'Takashi Murakami' },
              { name: 'Travis Scott' },
              { name: 'Yeezy' },
            ],
          },
        ],
      },
      {
        name: 'Bottoms',
        children: [
          {
            name: 'Bottoms',
            children: [{ name: 'Fear of God' }, { name: 'Supreme' }],
          },
          {
            name: 'Long Pants',
            children: [
              { name: 'acmé de la vie' },
              { name: 'adidas' },
              { name: 'ASSC' },
              { name: 'Awake' },
              { name: 'Balenciaga' },
              { name: 'BAPE' },
              { name: 'Carhartt WIP' },
              { name: 'Comme des Garçons' },
              { name: 'Fear of God' },
              { name: 'FTP' },
              { name: 'Heron Preston' },
              { name: 'Jordan' },
              { name: 'Juice Wrld' },
              { name: 'KAWS' },
              { name: 'KITH' },
              { name: 'NBA' },
              { name: 'Nike' },
              { name: 'Off-White' },
              { name: 'Palace' },
              { name: 'Stüssy' },
              { name: 'Supreme' },
              { name: 'TEAM WANG' },
              { name: 'Travis Scott' },
              { name: 'Virgil Abloh' },
              { name: 'Vlone' },
              { name: 'Yeezy' },
            ],
          },
          {
            name: 'Shorts',
            children: [
              { name: 'adidas' },
              { name: 'Balenciaga' },
              { name: 'BAPE' },
              { name: 'Carhartt WIP' },
              { name: 'Eric Emanuel' },
              { name: 'Fear of God' },
              { name: 'FTP' },
              { name: 'Jordan' },
              { name: 'Juice Wrld' },
              { name: 'KITH' },
              { name: 'NBA' },
              { name: 'Nike' },
              { name: 'Off-White' },
              { name: 'Palace' },
              { name: 'Stüssy' },
              { name: 'Supreme' },
              { name: 'Takashi Murakami' },
              { name: 'TEAM WANG' },
              { name: 'Travis Scott' },
            ],
          },
          {
            name: 'Skirts',
            children: [
              { name: 'Balenciaga' },
              { name: 'Comme des Garçons' },
              { name: 'Nike' },
              { name: 'Off-White' },
            ],
          },
          {
            name: 'Others',
            children: [
              { name: 'BAPE' },
              { name: 'Drew House' },
              { name: 'Fear of God' },
              { name: 'KITH' },
              { name: 'Nike' },
              { name: 'Off-White' },
              { name: 'Supreme' },
            ],
          },
        ],
      },
      {
        name: 'Headwear',
        children: [
          {
            name: 'Beanie',
            children: [
              { name: 'Awake' },
              { name: 'BAPE' },
              { name: 'Carhartt WIP' },
              { name: 'Fear of God' },
              { name: 'FTP' },
              { name: 'Heron Preston' },
              { name: 'KAWS' },
              { name: 'KITH' },
              { name: 'New Era' },
              { name: 'Nike' },
              { name: 'Off-White' },
              { name: 'Palace' },
              { name: 'Supreme' },
              { name: 'Takashi Murakami' },
              { name: 'TEAM WANG' },
              { name: 'Travis Scott' },
            ],
          },
          {
            name: 'Boonie / Bucket / Crusher',
            children: [
              { name: 'Awake' },
              { name: 'BAPE' },
              { name: 'KITH' },
              { name: 'Nike' },
              { name: 'Off-White' },
              { name: 'Palace' },
              { name: 'Stüssy' },
              { name: 'Supreme' },
              { name: 'Takashi Murakami' },
              { name: 'Travis Scott' },
            ],
          },
          {
            name: 'Caps',
            children: [
              { name: 'ASSC' },
              { name: 'Awake' },
              { name: 'BAPE' },
              { name: 'BTS' },
              { name: 'Carhartt WIP' },
              { name: 'Champion' },
              { name: 'Fear of God' },
              { name: 'FTP' },
              { name: 'Hater' },
              { name: 'Huf' },
              { name: 'Human Made' },
              { name: 'IKEA' },
              { name: 'Juice Wrld' },
              { name: 'KAWS' },
              { name: 'KITH' },
              { name: 'NEIGHBORHOOD' },
              { name: 'New Era' },
              { name: 'Nike' },
              { name: 'Obey' },
              { name: 'Off-White' },
              { name: 'Palace' },
              { name: 'Stüssy' },
              { name: 'Supreme' },
              { name: 'Takashi Murakami' },
              { name: 'The Cap City' },
              { name: 'The Hundreds' },
              { name: 'Travis Scott' },
              { name: 'UNDEFEATED' },
              { name: 'Virgil Abloh' },
              { name: 'Yeezy' },
            ],
          },
          { name: 'Headwear', children: [{ name: 'KAWS' }] },
          { name: 'T-Shirts', children: [{ name: 'Supreme' }] },
          {
            name: 'Others',
            children: [
              { name: 'adidas' },
              { name: 'ASSC' },
              { name: 'BAPE' },
              { name: 'FTP' },
              { name: 'Juice Wrld' },
              { name: 'KAWS' },
              { name: 'KITH' },
              { name: 'Nike' },
              { name: 'Palace' },
              { name: 'Supreme' },
              { name: 'Travis Scott' },
              { name: 'Vlone' },
            ],
          },
        ],
      },
      {
        name: 'Inner Wear',
        children: [
          {
            name: 'Swimwear',
            children: [{ name: 'Balenciaga' }, { name: 'Off-White' }],
          },
          {
            name: 'Thermal Wear',
            children: [{ name: 'BAPE' }, { name: 'Fear of God' }, { name: 'Supreme' }],
          },
          {
            name: 'Undergarment',
            children: [
              { name: 'Fear of God' },
              { name: 'FTP' },
              { name: 'Nike' },
              { name: 'Off-White' },
              { name: 'Supreme' },
              { name: 'Travis Scott' },
            ],
          },
          { name: 'Others', children: [{ name: 'BAPE' }, { name: 'Supreme' }] },
        ],
      },
      {
        name: 'One Piece',
        children: [
          {
            name: 'Dresses',
            children: [
              { name: 'Balenciaga' },
              { name: 'Comme des Garçons' },
              { name: 'Nike' },
              { name: 'Off-White' },
            ],
          },
          { name: 'One Piece', children: [{ name: 'Nike' }] },
          {
            name: 'Overalls',
            children: [{ name: 'BAPE' }, { name: 'KAWS' }, { name: 'Nike' }, { name: 'Supreme' }],
          },
          { name: 'Others', children: [{ name: 'Supreme' }] },
        ],
      },
      {
        name: 'Outerwear',
        children: [
          {
            name: 'Hoodie',
            children: [
              { name: 'Supreme' },
              { name: 'acmé de la vie' },
              { name: 'adidas' },
              { name: 'ASSC' },
              { name: 'Awake' },
              { name: 'Balenciaga' },
              { name: 'BAPE' },
              { name: 'Billionaire Boys Club' },
              { name: 'BTS' },
              { name: 'Cactus Plant Flea Market' },
              { name: 'Carhartt WIP' },
              { name: 'Champion' },
              { name: 'Chrome Hearts' },
              { name: 'Comme des Garçons' },
              { name: 'Drew House' },
              { name: 'Fear of God' },
              { name: 'FTP' },
              { name: 'Human Made' },
              { name: 'IKEA' },
              { name: 'Jordan' },
              { name: 'Juice Wrld' },
              { name: 'KAWS' },
              { name: 'KITH' },
              { name: 'NBA' },
              { name: 'NEIGHBORHOOD' },
              { name: 'Nike' },
              { name: 'Off-White' },
              { name: 'Palace' },
              { name: 'PANGAIA' },
              { name: 'Stüssy' },
              { name: 'Takashi Murakami' },
              { name: 'TEAM WANG' },
              { name: 'The North Face' },
              { name: 'Travis Scott' },
              { name: 'UNDEFEATED' },
              { name: 'Verdy' },
              { name: 'Virgil Abloh' },
              { name: 'Vlone' },
              { name: 'Yeezy' },
            ],
          },
          {
            name: 'Jackets',
            children: [
              { name: 'Supreme' },
              { name: 'adidas' },
              { name: 'ASSC' },
              { name: 'Awake' },
              { name: 'Balenciaga' },
              { name: 'BAPE' },
              { name: 'Carhartt WIP' },
              { name: 'Comme des Garçons' },
              { name: 'Fear of God' },
              { name: 'FTP' },
              { name: 'Heron Preston' },
              { name: 'Human Made' },
              { name: 'Jordan' },
              { name: 'KAWS' },
              { name: 'KITH' },
              { name: 'NBA' },
              { name: 'NEIGHBORHOOD' },
              { name: 'Nike' },
              { name: 'OAMC' },
              { name: 'Off-White' },
              { name: 'Palace' },
              { name: 'Stüssy' },
              { name: 'Takashi Murakami' },
              { name: 'TEAM WANG' },
              { name: 'Travis Scott' },
              { name: 'Yeezy' },
            ],
          },
          { name: 'Outerwear', children: [{ name: 'Fear of God' }] },
          { name: 'Shirts', children: [{ name: 'Supreme' }] },
          { name: 'Suit', children: [{ name: 'Supreme' }] },
          {
            name: 'Sweatshirt',
            children: [
              { name: 'Supreme' },
              { name: 'acmé de la vie' },
              { name: 'adidas' },
              { name: 'ASSC' },
              { name: 'Awake' },
              { name: 'Balenciaga' },
              { name: 'BAPE' },
              { name: 'BTS' },
              { name: 'Carhartt WIP' },
              { name: 'Comme des Garçons' },
              { name: 'Fear of God' },
              { name: 'FTP' },
              { name: 'Human Made' },
              { name: 'Jordan' },
              { name: 'Juice Wrld' },
              { name: 'KAWS' },
              { name: 'Kenzo' },
              { name: 'KITH' },
              { name: 'NBA' },
              { name: 'NEIGHBORHOOD' },
              { name: 'Nike' },
              { name: 'Off-White' },
              { name: 'Palace' },
              { name: 'PANGAIA' },
              { name: 'Stüssy' },
              { name: 'Takashi Murakami' },
              { name: 'TEAM WANG' },
              { name: 'Travis Scott' },
              { name: 'Verdy' },
            ],
          },
          { name: 'Tops', children: [{ name: 'Supreme' }] },
          {
            name: 'Others',
            children: [
              { name: 'BTS' },
              { name: 'Nike' },
              { name: 'Off-White' },
              { name: 'Palace' },
              { name: 'Supreme' },
            ],
          },
        ],
      },
      {
        name: 'Tops',
        children: [
          {
            name: 'Shirts',
            children: [
              { name: 'Supreme' },
              { name: 'adidas' },
              { name: 'ASSC' },
              { name: 'Balenciaga' },
              { name: 'BAPE' },
              { name: 'Carhartt WIP' },
              { name: 'Comme des Garçons' },
              { name: 'Drew House' },
              { name: 'Fear of God' },
              { name: 'Heron Preston' },
              { name: 'Jordan' },
              { name: 'KAWS' },
              { name: 'KITH' },
              { name: 'NBA' },
              { name: 'Nike' },
              { name: 'Off-White' },
              { name: 'Palace' },
              { name: 'Stüssy' },
              { name: 'Takashi Murakami' },
              { name: 'TEAM WANG' },
              { name: 'Travis Scott' },
              { name: 'Yeezy' },
            ],
          },
          {
            name: 'T-Shirts',
            children: [
              { name: 'Supreme' },
              { name: 'acmé de la vie' },
              { name: 'adidas' },
              { name: 'Aimé Leon Dore' },
              { name: 'ASSC' },
              { name: 'atmos' },
              { name: 'Awake' },
              { name: 'Balenciaga' },
              { name: 'BAPE' },
              { name: 'Billionaire Boys Club' },
              { name: 'BTS' },
              { name: 'Cactus Plant Flea Market' },
              { name: 'Carhartt WIP' },
              { name: 'Champion' },
              { name: 'Comme des Garçons' },
              { name: 'Daniel Arsham' },
              { name: 'Dior' },
              { name: 'Drew House' },
              { name: 'Fear of God' },
              { name: 'FR2' },
              { name: 'FTP' },
              { name: 'Givenchy' },
              { name: 'Heron Preston' },
              { name: 'Human Made' },
              { name: 'Ice Cream' },
              { name: 'IKEA' },
              { name: 'Jordan' },
              { name: 'Juice Wrld' },
              { name: 'KAWS' },
              { name: 'KITH' },
              { name: 'NBA' },
              { name: 'Nike' },
              { name: 'Novelship' },
              { name: 'OAMC' },
              { name: 'Off-White' },
              { name: 'Palace' },
              { name: 'PANGAIA' },
              { name: 'Stüssy' },
              { name: 'Takashi Murakami' },
              { name: 'TEAM WANG' },
              { name: 'The North Face' },
              { name: 'Travis Scott' },
              { name: 'UNDEFEATED' },
              { name: 'UNDERCOVER' },
              { name: 'Verdy' },
              { name: 'Virgil Abloh' },
              { name: 'Vlone' },
              { name: 'Yeezy' },
            ],
          },
          { name: 'Caps', children: [{ name: 'Supreme' }] },
          { name: 'Hoodie', children: [{ name: 'Supreme' }] },
          {
            name: 'Others',
            children: [{ name: 'Nike' }, { name: 'Supreme' }, { name: 'Yeezy' }],
          },
        ],
      },
    ],
  },
  {
    name: 'Collectibles',
    initialShowLimit: 10,
    children: [
      {
        name: 'Electronics',
        children: [
          { name: 'Nintendo Switch' },
          { name: 'PS5' },
          { name: 'Xbox', children: [{ name: 'Series S' }, { name: 'Series X' }] },
          { name: 'Oculus' },
          { name: 'Others' },
        ],
      },
      {
        name: 'Bearbrick',
        children: [
          { name: '100%' },
          { name: '100% & 400%' },
          { name: '200%' },
          { name: '400%' },
          { name: '1000%' },
        ],
      },
      {
        name: 'KAWS',
        children: [
          { name: 'Basketball' },
          { name: 'Keychains & Pins' },
          { name: 'Plush Figures' },
          { name: 'Puzzles' },
          { name: 'Vinyl Figures' },
          { name: 'Others' },
        ],
      },
      {
        name: 'Action Figures',
        children: [
          { name: 'Dragon Ball' },
          { name: 'Futura' },
          { name: 'Gundam' },
          { name: 'One Piece' },
          { name: 'Others', children: [{ name: '100%' }] },
        ],
      },
      { name: 'Skate' },
      {
        name: 'Interior',
        children: [
          {
            name: 'Chinatown Market',
            children: [{ name: 'Rugs' }, { name: 'Others' }],
          },
          {
            name: 'IKEA',
            children: [{ name: 'Electric Appliances' }, { name: 'Rugs' }, { name: 'Others' }],
          },
          { name: 'Mats & Rugs' },
          { name: 'Others' },
        ],
      },
      {
        name: 'Toys',
        children: [
          {
            name: 'LEGO',
            children: [
              { name: 'Harry Potter' },
              { name: 'Marvel' },
              { name: 'Star Wars' },
              { name: 'Others' },
            ],
          },
        ],
      },
      {
        name: 'Accessories',
        children: [
          { name: 'Eyewear' },
          { name: 'Keychains & Pins' },
          { name: 'Phone / Laptop' },
          { name: 'Plush Figures', children: [{ name: 'Labubu' }] },
          { name: 'Others' },
        ],
      },
      {
        name: 'Others',
        children: [
          { name: 'Accessories' },
          { name: 'Basketball' },
          { name: 'Beanie' },
          { name: 'Eyewear' },
          { name: 'Figurines' },
          { name: 'Keychains & Pins' },
          { name: 'Mats & Rugs' },
          { name: 'Phone / Laptop' },
          { name: 'Puzzles' },
          { name: 'Toys' },
          { name: 'Other Sports Balls' },
          { name: 'Others' },
        ],
      },
    ],
  },
  {
    name: 'Trading Cards',
    initialShowLimit: 10,
    children: [
      {
        name: 'Graded Cards',
        children: [
          { name: 'Baseball' },
          { name: 'Basketball' },
          { name: 'Pokemon' },
          { name: 'Soccer TCG' },
        ],
      },
      {
        name: 'Sealed Boxes',
        children: [
          { name: 'Baseball' },
          { name: 'Basketball' },
          { name: 'Magic: The Gathering' },
          { name: 'Pokemon' },
          { name: 'Sealed Boxes' },
          { name: 'Soccer TCG' },
          { name: 'Yu-Gi-Oh!' },
        ],
      },
    ],
  },
];

const APPAREL_BRANDS = {
  initialShowLimit: 10,
  children: [
    {
      name: 'Supreme',
    },
    {
      name: 'Fear of God',
    },
    {
      name: 'Travis Scott',
    },
    {
      name: 'BAPE',
    },
    {
      name: 'ASSC',
    },
    {
      name: 'KAWS',
    },
    {
      name: 'Off-White',
    },
    {
      name: 'Palace',
    },
    {
      name: 'KITH',
    },
    {
      name: 'adidas',
    },
    {
      name: 'atmos',
    },
    {
      name: 'Awake',
    },
    {
      name: 'Billionaire Boys Club',
    },
    {
      name: 'BTS',
    },
    {
      name: 'Cactus Plant Flea Market',
    },
    {
      name: 'Carhartt WIP',
    },
    {
      name: 'Champion',
    },
    {
      name: 'Chrome Hearts',
    },
    {
      name: 'Comme des Garçons',
    },
    {
      name: 'Daniel Arsham',
    },
    {
      name: 'Eric Emanuel',
    },
    {
      name: 'FTP',
    },
    {
      name: 'Givenchy',
    },
    {
      name: 'Heron Preston',
    },
    {
      name: 'IKEA',
    },
    {
      name: 'Jordan',
    },
    {
      name: 'Juice Wrld',
    },
    {
      name: 'Kenzo',
    },
    {
      name: 'Louis Vuitton',
    },
    {
      name: 'Marc Jacobs',
    },
    {
      name: 'Nike',
    },
    {
      name: 'OAMC',
    },
    {
      name: 'PANGAIA',
    },
    {
      name: 'Stüssy',
    },
    {
      name: 'Takashi Murakami',
    },
    {
      name: 'TEAM WANG',
    },
    {
      name: 'UNDEFEATED',
    },
    {
      name: 'UNDERCOVER',
    },
    {
      name: 'Verdy',
    },
    {
      name: 'Virgil Abloh',
    },
    {
      name: 'Vlone',
    },
    {
      name: 'Yeezy',
    },
  ],
};
const TOP_LEVEL_CATEGORIES_CLASS_MAP = {
  'Trading Cards': 'Collectibles',
};
export { APPAREL_BRANDS, TOP_LEVEL_CATEGORIES_CLASS_MAP };
export default BROWSE_DATA;
